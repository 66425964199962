import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";

import { useDarkMode } from "../utils/DarkModeContext";
import LightMode from "../assets/LightMode";
import DarkMode from "../assets/DarkMode";

import Logo from "./Logo";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Link as ScrollLink } from "react-scroll";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Header() {
  const [scrolling, setScrolling] = useState(false);
  const { isDarkMode, toggleDarkMode } = useDarkMode();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > 10) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Popover
      className={`sticky top-0 z-50 bg-transparent ${scrolling
        ? "bg-white/20 dark:bg-opacity-40"
        : "bg-transparent dark:bg-transparent"
        } dark:bg-olenium-bg transition-colors duration-500 ease-in-out backdrop-blur-md`}
    >
      <div className="relative z-20">
        <div className="max-w-full md:max-w-full lg:max-w-7xl xl:max-w-7xl mx-4 md:mx-8 lg:mx-auto xl:mx-auto flex justify-between items-center px-4 py-3 sm:px-6 sm:py-3 lg:px-12 xl:px-16">
          <button
            onClick={toggleDarkMode}
            className="p-2 rounded text-2xl order-1"
          >
            {isDarkMode ? <LightMode /> : <DarkMode />}
          </button>
          <div className="order-2">
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <Link
            // link to portal.olenium.com
            to="https://portal.olenium.co.uk"
            className="rounded-md bg-olenium-blue px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-[#309CFF] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-olenium-blue order-3"
          >
            Get started
          </Link>
        </div>
      </div>
    </Popover >
  );
}

export default Header;
