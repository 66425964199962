import Lottie from "react-lottie";
import uptimeAnimation from "../assets/lottie/Globe.json";
import lockAnimation from "../assets/lottie/Lock.json";
import { useEffect, useState } from "react";

export default function FeatureGrid() {
  const [currentAnimation, setCurrentAnimation] = useState(lockAnimation);
  // Globe animation options
  const globeAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: uptimeAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Lock animation options
  const lockAnimationOptions = {
    loop: true,
    autoplay: true,
    mode: "scroll",
    animationData: lockAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const TypingAnimation = () => {
    // Array of domain examples to cycle through
    const domainsSets = [
      // First set of domains for various services
      ["eatfreshly.com", "studybuddy.net", "planitevent.com"],

      // Second set of domains for professional servicesp
      ["lawhelp.co.uk", "finadvice.org", "medconsult.io"],
    ];


    const [currentSetIndex, setCurrentSetIndex] = useState(0);
    const [currentDomain, setCurrentDomain] = useState("");
    const [isDeleting, setIsDeleting] = useState(false);
    const [domainIndex, setDomainIndex] = useState(0);
    const [typingSpeed, setTypingSpeed] = useState(150);

    useEffect(() => {
      let timer;
      if (isDeleting) {
        setTypingSpeed(30);
        timer = setTimeout(() => {
          setCurrentDomain((current) => current.slice(0, current.length - 1));
        }, typingSpeed);
      } else {
        timer = setTimeout(() => {
          setCurrentDomain(
            domainsSets[currentSetIndex][domainIndex].slice(
              0,
              currentDomain.length + 1
            )
          );
        }, typingSpeed);
      }

      if (
        !isDeleting &&
        currentDomain === domainsSets[currentSetIndex][domainIndex]
      ) {
        setIsDeleting(true);
        setTypingSpeed(1500); // Pause before deleting
      } else if (isDeleting && currentDomain === "") {
        setIsDeleting(false);
        setDomainIndex(
          (domainIndex) =>
            (domainIndex + 1) % domainsSets[currentSetIndex].length
        );
        setTypingSpeed(500); // Pause before typing next domain

        // Move to the next set of domains after the last one in the current set
        if (domainIndex === domainsSets[currentSetIndex].length - 1) {
          setCurrentSetIndex((currentSetIndex + 1) % domainsSets.length);
        }
      }

      return () => clearTimeout(timer);
    }, [
      currentDomain,
      isDeleting,
      domainIndex,
      currentSetIndex,
      domainsSets,
      typingSpeed,
    ]);

    return (
      <div className="text-black font-mono text-3xl tracking-wide uppercase my-10 dark:text-white">
        {currentDomain}
        <span className="typing-cursor">|</span>
      </div>
    );
  };

  const svgVariants = {
    hidden: {
      pathLength: 0,
      fillOpacity: 0,
    },
    visible: {
      pathLength: 1,
      fillOpacity: 1,
      transition: {
        pathLength: { type: "spring", duration: 2, bounce: 0 },
        fillOpacity: { duration: 1, delay: 2 },
      },
    },
  };

  return (
    <div className="bg-white dark:bg-olenium-bg pt-24">
      <div className="mx-auto max-w-7xl p-4 md:p-8 md:text-left xl:max-w-7xl justify-start">
        <p className="text-base font-semibold tracking-wide text-white uppercase pb-4">
          Hosting
        </p>
        <h2 className="text-3xl tracking-tight text-grey-900 sm:text-4xl md:text-5xl lg:text-6xl font-extrabold text-gray-900 dark:text-white">
          Reliable hosting.
          <br />
          Unbeatable performance.
        </h2>
        <p className="mt-6 text-lg tracking-tight text-gray-600 dark:text-white xl:max-w-3xl">
          We manage your website hosting with Amazon Web Services (AWS), using
          S3 for storage and CloudFront for fast content delivery. Our
          infrastructure ensures your site is easily accessible and performs
          optimally worldwide. We handle the technical details, allowing you to
          focus on what matters most, without any complex setup on your part.
        </p>
      </div>
      <div className="mx-auto max-w-7xl p-4 md:p-8 md:text-left xl:max-w-7xl justify-start">
        {/* Fourth row */}
        <div className="md:col-span-3">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8">
            <div className="col-span-1 bg-gray-200 dark:bg-gray-900 p-8 text-white rounded-3xl p-4 md:p-12 justify-start md:text-left">

              <div className="justify-start md:text-left flex flex-col justify-between h-full">
                <div>
                  <p className="text-base font-semibold tracking-wide text-black dark:text-white uppercase pb-4">
                    Custom Domain
                  </p>
                  {/* Typing Animation Component */}
                  <TypingAnimation />
                </div>
                <p className="text-lg tracking-tight text-gray-800 dark:text-white xl:max-w-3xl pt-4">
                  Our service offers custom domains, enabling you to personalize
                  your website's URL to reflect your brand. We streamline the
                  registration or transfer process, providing easy integration
                  and dedicated support to ensure your site stands out with a
                  professional and memorable web address.
                </p>
              </div>
            </div>
            {/* Add border with gradient from transparent to 7C9FB1 */}
            {/* Updated div with gradient border */}
            <div className="relative col-span-1 bg-[#181818] p-8 text-white rounded-3xl md:p-12">
              <div
                className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-r from-transparent to-[#7C9FB1] rounded-3xl"
                style={{ zIndex: -1 }}
              ></div>
              <div className="relative">
                <div className="justify-start md:text-left">
                  <p className="text-base font-semibold tracking-wide text-white uppercase pb-4">
                    Content Delivery Network
                  </p>
                  {/* Lottie Animation */}
                  <div className="-mx-28 my-8">
                    <Lottie
                      options={globeAnimationOptions}
                      className="h-full w-full"
                    />
                  </div>
                  <h3 className="text-2xl tracking-tight text-white sm:text-2xl md:text-2xl lg:text-3xl font-extrabold text-gray-900 dark:text-white text-center">
                    Always online. Always available.
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-span-1 bg-gradient-to-r from-blue-400 to-blue-600 p-8 text-white rounded-3xl p-4 md:p-12">
              <div className="justify-start md:text-left flex flex-col justify-between h-full">
                <div>
                  <p className="text-base font-semibold tracking-wide text-white uppercase pb-4">
                    SSL Certificate
                  </p>
                  <div className="flex items-center justify-center w-full h-24 my-16">
                    <div className="flex items-center justify-center h-36 w-36 rounded-full bg-transparent border-4 border-white">
                      <div className="h-50 w-50">
                        <Lottie options={lockAnimationOptions} />
                      </div>
                    </div>
                  </div>
                </div>
                <p className="text-lg tracking-tight text-white xl:max-w-3xl pt-4">
                  Our service provides SSL certificates, enhancing your
                  website's security and trust. The setup is automated, offering
                  encryption, user data protection, and improved search
                  rankings. With our support, your site gains essential security
                  and credibility, vital for user confidence and success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
