import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/solid";
import { Popover } from "@headlessui/react";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import { useState, useEffect } from "react";
import { useDarkMode } from "../utils/DarkModeContext";
import LightMode from "../assets/LightMode";
import DarkMode from "../assets/DarkMode";
import { useNavigate } from "react-router-dom";

const frequencies = [
  { value: "monthly", label: "Monthly", priceSuffix: "/month" },
  { value: "annually", label: "Annually", priceSuffix: "/year" },
];
const tiers = [
  {
    name: "Basic",
    id: "basic",
    href: {
      "one-page": {
        monthly: "https://buy.stripe.com/14kdTqh2k3Zh7S03cI",
        annually: "https://buy.stripe.com/cN28z6dQ89jB7S08x3",
      },
      "multi-page": {
        monthly: "https://buy.stripe.com/cN29Da7rKbrJ7S0aEW",
        annually: "https://buy.stripe.com/4gw2aI8vO67pdck00p",
      },
    },
    price: { monthly: "£25", annually: "£250" },
    saving: "£50",
    description: "The essentials to provide your best work for clients.",
    features: [
      "Standard server",
      "Included domain name",
      "SSL certificate",
      "Access our Freelancer Network",
    ],
    mostPopular: false,
  },
  {
    name: "Business",
    id: "business",
    href: {
      "one-page": {
        monthly: "https://buy.stripe.com/eVa02A6nG3Zh5JS5kS",
        annually: "https://buy.stripe.com/28o5mUcM48fxfks28H",
      },
      "multi-page": {
        monthly: "https://buy.stripe.com/14kaHe27qgM37S028o",
        annually: "https://buy.stripe.com/14k8z67rK9jB4FOcN7",
      },
    },
    price: { monthly: "£45", annually: "£450" },
    saving: "£90",
    description: "A plan that scales with your rapidly growing business.",
    features: [
      "Dedicated server",
      "Included domain name",
      "SSL certificate",
      "24/7 support based in UK",
      "Advanced monitoring",
      "Access our Freelancer Network",
    ],
    mostPopular: true,
  },
  {
    name: "Enterprise",
    id: "enterprise",
    href: {
      "one-page": {
        monthly: "https://buy.stripe.com/6oE7v29zSeDV1tC9Ba",
        annually: "https://buy.stripe.com/9AQ9DacM467pfks14F",
      },
      "multi-page": {
        monthly: "https://buy.stripe.com/6oE7v2eUcbrJegofZl",
        annually: "https://buy.stripe.com/14k6qYeUcbrJc8g4gC",
      },
    },
    price: { monthly: "£85", annually: "£850" },
    saving: "£170",
    description: "Dedicated support and infrastructure for your company.",
    features: [
      "High resourced dedicated server",
      "Included domain name",
      "SSL certificate",
      "24/7 support based in UK",
      "Advanced monitoring",
      "Access our Freelancer Network",
      "Advanced analytics and reporting",
      "Dedicated account manager",
      "Custom and secure business email",
    ],
    mostPopular: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const [scrolling, setScrolling] = useState(false);
  const { isDarkMode, toggleDarkMode } = useDarkMode();
  const navigate = useNavigate();
  const [selectedTierState, setSelectedTierState] = useState("one-page");
  const [frequency, setFrequency] = useState(frequencies[0]); // Initialize with the first frequency option

  useEffect(() => {
    const storedTier = localStorage.getItem("tier");
    if (storedTier) {
      setSelectedTierState(storedTier);
    } else {
      // If no tier is stored, redirect to the 'get started' page
      navigate("/get-started");
    }
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-white dark:bg-olenium-bg">
      <Popover
        className={`sticky top-0 z-50 bg-white ${scrolling
          ? "bg-opacity-40 dark:bg-opacity-40"
          : "bg-transparent dark:bg-transparent"
          } dark:bg-olenium-bg transition-colors duration-500 ease-in-out backdrop-blur-md`}
      >
        <div className="relative z-20">
          <div className="max-w-full md:max-w-full lg:max-w-7xl xl:max-w-7xl mx-4 md:mx-8 lg:mx-auto xl:mx-auto flex justify-between items-center px-4 py-3 sm:px-6 sm:py-3 lg:px-12 xl:px-16">
            <button
              onClick={toggleDarkMode}
              className="p-2 rounded text-2xl order-1"
            >
              {isDarkMode ? <LightMode /> : <DarkMode />}
            </button>
            <div className="order-2">
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <Link
              to="/"
              className="rounded-md bg-gray-700 dark:bg-white/10 px-3.5 py-1.5 text-sm font-semibold text-white hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-olenium-blue order-3"
            >
              Learn more
            </Link>
          </div>
        </div>
      </Popover>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <p className="mt-16 text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl">
            Choose your hosting plan
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600 dark:text-gray-300">
          We offer a range of plans to suit your business needs. Whether you're
          a freelancer or a growing enterprise, we've got you covered.
        </p>
        <div className="mt-16 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            <RadioGroup.Label className="sr-only">
              Payment frequency
            </RadioGroup.Label>
            {frequencies.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked ? "bg-olenium-blue text-white" : "text-gray-500",
                    "cursor-pointer rounded-full px-2.5 py-1"
                  )
                }
              >
                <span>{option.label}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3 mb-12">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular
                  ? "ring-2 ring-olenium-blue"
                  : "ring-1 ring-gray-200",
                "rounded-3xl p-8 xl:p-10"
              )}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id={tier.id}
                  className={classNames(
                    tier.mostPopular
                      ? "text-olenium-blue"
                      : "text-gray-900 dark:text-white",
                    "text-lg font-semibold leading-8"
                  )}
                >
                  {tier.name}
                </h3>
                {tier.mostPopular ? (
                  <p className="rounded-full bg-olenium-blue/10 px-2.5 py-1 text-xs font-semibold leading-5 text-olenium-blue dark:text-white">
                    Most popular
                  </p>
                ) : null}
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-600 dark:text-gray-200">
                {tier.description}
              </p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-gray-900 dark:text-white">
                  {tier.price[frequency.value]}
                </span>
                <span className="text-sm font-semibold leading-6 text-gray-600 dark:text-white">
                  {frequency.priceSuffix}
                </span>
              </p>
              {tier.price[frequency.value] === tier.price.annually ? (
                <p className="text-xs font-semibold tracking-wide text-gray-500 dark:text-white mt-2">
                  You're saving {tier.saving} annually.
                </p>
              ) : (
                <p className="text-xs font-semibold tracking-wide text-gray-500 dark:text-white mt-2">
                  You could save {tier.saving} annually.
                </p>
              )}

              <Link
                // find the correct link based on the tier and frequency
                // Accessing links with checks
                to={tier.href[selectedTierState]?.[frequency.value] ?? "/"}
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? "bg-olenium-blue text-white shadow-sm hover:bg-olenium-blue"
                    : "text-olenium-blue ring-1 ring-inset ring-olenium-blue hover:ring-olenium-blue",
                  "mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-olenium-blue"
                )}
              >
                Choose Plan
              </Link>
              <ul
                role="list"
                className="mt-8 space-y-3 text-sm leading-6 text-gray-600 dark:text-white xl:mt-10"
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-olenium-blue"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <footer className="bg-black" aria-labelledby="footer-heading">
        <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:py-8 lg:px-8">
          <div className="pt-8 lg:flex lg:items-center lg:justify-between xl:mt-0"></div>
          <div className="mt-8 border-t border-white pt-8 md:flex md:items-center md:justify-between lg:mt-0">
            {/* <p className="mt-8 text-base text-white md:mt-0 md:order-1">
            &copy; {new Date().getFullYear()} It's One Page
          </p> */}
            <svg
              width="100"
              height="20"
              viewBox="0 0 694 214"
              className="fill-white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clipRule="evenodd"
                d="M0 106C0 47.4588 47.4588 0 106 0C164.512 0 211.971 47.4588 212 106C212 164.541 164.541 212 106 212C47.4588 212 0 164.541 0 106ZM105.5 174C142.779 174 173 143.779 173 106.5C173 69.2208 142.779 39 105.5 39C68.2208 39 38 69.2208 38 106.5C38 143.779 68.2208 174 105.5 174Z"
              />
              <path d="M243 110.267V34.3774C319.429 53.479 395.887 72.5805 472.317 91.682C472.317 71.7475 472.317 51.7842 472.317 31.8497C472.317 14.8163 486.067 1.02872 503.054 1H512.107V213.099H503.914C486.267 213.099 472.001 198.708 472.116 181.043C472.202 168.088 472.259 155.134 472.345 142.179L290.811 95.5024C290.811 111.961 290.754 128.449 290.754 144.908L277.261 144.822C258.297 144.707 243 129.282 243 110.267Z" />
              <path d="M693.183 82.8546H550V131.326H693.183V82.8546Z" />
              <path d="M550 162.737H693.183V179.233C693.183 196.884 678.87 211.208 661.233 211.208H550V162.737Z" />
              <path d="M551.578 3H661.289C678.898 3 693.211 17.3242 693.211 34.9475V49.892C693.211 50.7661 692.506 51.4711 691.633 51.4711H551.578C550.704 51.4711 550 50.7661 550 49.892V4.57905C550 3.70493 550.704 3 551.578 3Z" />
            </svg>
            <p className="mt-8 text-base text-white md:mt-0 md:order-1">
              Developed by{" "}
              <a
                href="https://olenium.co.uk"
                className="text-white hover:text-gray-300"
              >
                Olenium
              </a>{" "}
              in Newcastle
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
