import React from "react";

function DarkMode() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 121 121"
      className="fill-black"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="60.5" cy="60.5" r="60.5" fill="#000000" fill-opacity="0.05" />
      <path
        d="M87.17 60.7103C87.17 75.3238 75.3235 87.1703 60.71 87.1703C46.0965 87.1703 34.25 75.3238 34.25 60.7103C34.25 46.0969 46.0965 34.2503 60.71 34.2503C62.6541 34.2405 64.5926 34.4608 66.485 34.9066C58.9955 37.051 54.0937 44.2236 54.8168 51.9805C55.5399 59.7373 61.683 65.8804 69.4398 66.6035C77.1967 67.3266 84.3693 62.4248 86.5137 54.9353C86.9595 56.8277 87.1798 58.7662 87.17 60.7103Z"
        fill="black"
      />
    </svg>
  );
}

export default DarkMode;
