import {
  BsLayoutWtf,
  BsPhone,
  BsBarChart,
  BsShare,
  BsLightning,
  BsJournalCheck,
} from "react-icons/bs";

import animation from "../assets/animation.gif";

const features = [
  {
    name: "User-Friendly Design",
    description:
      "Easy navigation and an intuitive interface ensure a seamless user experience, making your website accessible to all visitors.",
    icon: BsLayoutWtf,
  },
  {
    name: "Mobile Optimisation",
    description:
      "our website automatically adjusts to look perfect on any device, ensuring a great experience for users on phones, tablets, or desktops.",
    icon: BsPhone,
  },
  {
    name: "SEO Ready",
    description:
      "Enhanced for search engines right from the start, helping your business get found easily on Google and other search platforms.",
    icon: BsBarChart,
  },
  {
    name: "Social Integration",
    description:
      "Seamlessly connect with social media platforms, allowing for easy sharing and increased engagement with your audience.",
    icon: BsShare,
  },
  {
    name: "Fast Loading",
    description:
      "Optimized for speed, ensuring your website loads quickly for a smooth and efficient user experience.",
    icon: BsLightning,
  },
  {
    name: "Custom Contact Forms",
    description:
      "Tailored forms for inquiries, feedback, or quotes, making it easy for customers to connect with you directly from the website.",
    icon: BsJournalCheck,
  },
];

export default function Features(props) {
  return (
    <div className="bg-white dark:bg-olenium-bg pt-24" id={props.id}>
      <div className="mx-auto max-w-7xl p-4 md:p-8 md:text-left xl:max-w-7xl justify-start">
        <p className="text-base font-semibold tracking-wide text-white uppercase pb-4">
          No Complications. No Hidden Costs.
        </p>
        <h2 className="text-3xl tracking-tight text-grey-900 sm:text-4xl md:text-5xl lg:text-6xl font-extrabold text-gray-900 dark:text-white">
          Even the small things
          <br />
          are a big deal.
        </h2>
        <p className="mt-6 text-lg tracking-tight text-gray-600 dark:text-white xl:max-w-3xl">
          We enrich your online presence with a wide array of
          features: from user-friendly designs and mobile optimisation, to SEO
          enhancement, social media integration, rapid loading times, and custom
          contact forms, ensuring a comprehensive, efficient, and engaging user
          experience.
        </p>
      </div>

      <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
        <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 dark:text-white sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name} className="relative pl-9">
              <dt className="inline font-semibold text-gray-900 dark:text-olenium-blue">
                <feature.icon
                  className="absolute left-1 top-1 h-5 w-5 text-olenium-blue dark:text-olenium-blue"
                  aria-hidden="true"
                />
                {feature.name}
              </dt>{" "}
              <dd className="inline">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
