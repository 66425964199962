import React, { useState } from 'react';

export default function GetStartedForm({ showModal, setShowModal, message }) {


    const [step, setStep] = useState(1);

    const getStepClass = (stepNumber) => {
        return step >= stepNumber
            ? "flex items-center text-olenium-blue space-x-2.5 rtl:space-x-reverse"
            : "flex items-center text-gray-500 space-x-2.5 rtl:space-x-reverse";
    };


    const nextStep = () => {
        setStep(prev => prev + 1);
    };

    const prevStep = () => {
        setStep(prev => prev - 1);
    };

    const Step1Content = () => (
        <form action="#" method="POST" class="mx-auto my-8 max-w-xl sm:mt-8">
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 mb-16">
                <div class="sm:col-span-2">
                    <label for="message" class="block text-sm font-semibold leading-6 text-gray-900">Tell us about your project</label>
                    <div class="mt-2.5">
                        <textarea name="message" id="message" rows="6" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                    </div>
                </div>
            </div>
            <button onClick={nextStep} class="block w-full rounded-lg bg-olenium-blue px-3 py-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-olenium-blue">Next</button>
        </form>
    );

    const Step2Content = () => (
        <form action="#" method="POST" class="mx-auto mt-8 max-w-xl sm:mt-10">
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                <div>
                    <label for="first-name" class="block text-sm font-semibold leading-6 text-gray-900">First name</label>
                    <div class="mt-2.5">
                        <input type="text" name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                <div>
                    <label for="last-name" class="block text-sm font-semibold leading-6 text-gray-900">Last name</label>
                    <div class="mt-2.5">
                        <input type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                <div class="sm:col-span-2">
                    <label for="email" class="block text-sm font-semibold leading-6 text-gray-900">Email</label>
                    <div class="mt-2.5">
                        <input type="email" name="email" id="email" autocomplete="email" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                <div class="sm:col-span-2">
                    <label for="phone-number" class="block text-sm font-semibold leading-6 text-gray-900">Phone number</label>
                    <div class="mt-2.5">
                        <input type="text" name="tel" id="tel" autocomplete="tel" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>
            </div>
            <button onClick={nextStep} class="block w-full mt-6 rounded-lg bg-olenium-blue px-3 py-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-olenium-blue">Next</button>
            <button type="button" class=" w-full text-gray-900 hover:text-gray-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2  dark:text-gray-400 dark:hover:text-gray-100  mt-4" onClick={prevStep}>Back</button>
        </form>

    );

    const Step3Content = () => (
        <form action="#" method="POST" class="mx-auto my-8 max-w-xl sm:mt-8">
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 mb-16">
                <div class="sm:col-span-2">
                    <label for="company" class="block text-sm font-semibold leading-6 text-gray-900">Company</label>
                    <div class="mt-2.5">
                        <input type="text" name="company" id="company" autocomplete="organization" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>

                <div class="sm:col-span-2">
                    <label for="message" class="block text-sm font-semibold leading-6 text-gray-900">Message</label>
                    <div class="mt-2.5">
                        <textarea name="message" id="message" rows="3" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                    </div>
                </div>
            </div>
            <button onClick={nextStep} class="block w-full rounded-lg bg-olenium-blue px-3 py-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-olenium-blue">Next</button>
            <button type="button" class=" w-full text-gray-900 hover:text-gray-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2  dark:text-gray-400 dark:hover:text-gray-100  mt-4" onClick={prevStep}>Back</button>
        </form>
    );

    const renderStep = () => {
        switch (step) {
            case 1:
                return <Step1Content />;
            case 2:
                return <Step2Content />;
            case 3:
                return <Step3Content />;
            default:
                return <Step1Content />;
        }
    };



    return (
        // modal should appear in the middle of the screen
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50 backdrop-filter backdrop-blur-lg" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="relative p-2 w-full max-w-4xl max-h-full">
                <div class="relative bg-white rounded-3xl shadow px-10 pb-10 modal-content">

                    <div class="flex items-center justify-between mt-6">
                        <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-200 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-200 dark:hover:text-white mt-8 md:mt-0" onClick={() => setShowModal(false)}>
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>

                    <h3 class="text-3xl font-semibold text-gray-800 mb-8 md:mt-0 text-center">
                        Let's make a start
                    </h3>

                    <ol className="items-center space-y-4 sm:flex sm:space-x-8 sm:space-y-0 rtl:space-x-reverse justify-between mx-16 mb-12">
                        <li className={getStepClass(1)}>
                            <span class="flex items-center justify-center w-8 h-8 border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
                                1
                            </span>
                            <span>
                                <h3 class="font-medium leading-tight">Project details</h3>
                                <p class="text-sm">Step 1 of 3</p>
                            </span>
                        </li>
                        <li className={getStepClass(2)}>
                            <span class="flex items-center justify-center w-8 h-8 border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
                                2
                            </span>
                            <span>
                                <h3 class="font-medium leading-tight">Contact infomation</h3>
                                <p class="text-sm">Step 2 of 3</p>
                            </span>
                        </li>
                        <li className={getStepClass(3)}>
                            <span class="flex items-center justify-center w-8 h-8 border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
                                3
                            </span>
                            <span>
                                <h3 class="font-medium leading-tight">What's next?</h3>
                                <p class="text-sm">Step 3 of 3</p>
                            </span>
                        </li>
                    </ol>

                    <div class="step-content">
                        {renderStep()}
                    </div>
                </div>
            </div>
        </div>
    );
}
