import Logo from "./Logo";

export default function Example() {
  return (
    <footer className="bg-black" aria-labelledby="footer-heading">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:py-8 lg:px-8">
        <div className="pt-8 lg:flex lg:items-center lg:justify-between xl:mt-0"></div>
        <div className="mt-8 border-t border-white pt-8 md:flex md:items-center md:justify-between lg:mt-0">
          <Logo />
          <p className="mt-8 text-base text-white md:mt-0 md:order-1">
            &copy; {new Date().getFullYear()} Olenium. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
