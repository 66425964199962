import logo1 from "../assets/logo-dark.png";

import person1 from "../assets/testimonials/person1_1_11zon.jpg";
import person2 from "../assets/testimonials/person2_2_11zon.jpg";
import person3 from "../assets/testimonials/person3_3_11zon.jpg";
import person4 from "../assets/testimonials/person4_4_11zon.jpg";
import person5 from "../assets/testimonials/person5_5_11zon.jpg";
import person6 from "../assets/testimonials/person6_6_11zon.jpg";
import person7 from "../assets/testimonials/person7_7_11zon.jpg";
import person8 from "../assets/testimonials/person8_8_11zon.jpg";
import person9 from "../assets/testimonials/person9_9_11zon.jpg";
import person10 from "../assets/testimonials/person10_10_11zon.jpg";

const featuredTestimonial = {
  body: "As a business owner in the drainage and civil engineering sector, I have always believed in the power of simplicity and efficiency. This is why when it came to revamping our website for EnviroRod, I chose Olenium to handle the job. Their approach to web design perfectly aligned with our core values - clear, concise, and highly effective.",
  author: {
    name: "Callum Oliver",
    company: "EnviroRod",
    imageUrl: person1,
    logoUrl: logo1,
  },
};
const testimonials = [
  [
    [
      {
        body: "They took the time to understand our unique requirements - from showcasing our range of Oktoberfest tables and benches to detailing our event-specific services. Their approach to design and functionality has made our website not only a visual treat but also incredibly user-friendly.",
        author: {
          name: "Nigel",
          company: "Event Bench Hire",
          imageUrl: person2,
        },
      },
      {
        body: "Olenium brilliantly showcased our bakery's charm. The website is user-friendly and beautifully designed, enhancing our customer reach.",
        author: {
          name: "Tom Bennett",
          company: "Bread and Crumb",
          imageUrl: person4,
        },
      },
    ],
    [
      {
        body: "Delighted with our sleek, one-page website from 'Its One Page'. It perfectly captures our photography business's essence and attracts more clients.",
        author: {
          name: "Emily Clarkson",
          company: "Urbex Photography",
          imageUrl: person3,
        },
      },
      {
        body: "Olenium created a vibrant, easy-to-navigate website for our nursery. Their service is top-notch, reflecting our passion for gardening.",
        author: {
          name: "David Smith",
          company: "Garden Delights Nursery",
          imageUrl: person6,
        },
      },
    ],
  ],
  [
    [
      {
        body: "Our legal consultancy's professionalism is now mirrored in our website, thanks to 'Its One Page.' It's clear, concise, and effective.",
        author: {
          name: "Lisa Thompson",
          company: "Thompson Legal",
          imageUrl: person8,
        },
      },
      {
        body: "Getting online was a breeze with 'Its One Page'. Our plumbing services are now showcased on a fantastic, user-friendly website.",
        author: {
          name: "James O’Connor",
          company: "No Leaks",
          imageUrl: person7,
        },
      },
    ],
    [
      {
        body: "Olenium brought our event planning business to life online with a stunning website. It's visually appealing and informative.",
        author: {
          name: "Sarah Hughes",
          company: "Wildflower Events",
          imageUrl: person5,
        },
      },
      {
        body: "Our café's unique vibe is now perfectly captured online, thanks to the creative team at 'Its One Page.' Fast and impressive service.",
        author: {
          name: "Richard Allen",
          company: "Allen's Café",
          imageUrl: person10,
        },
      },
      {
        body: "The essence of our yoga studio is beautifully presented on a single page by 'Its One Page'. Our clients love the simplicity.",
        author: {
          name: "Grace Martin",
          company: "Tranquil Yoga Studio",
          imageUrl: person9,
        },
      },
    ],
  ],
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Testimonials(props) {
  return (
    <div
      className="relative isolate bg-white dark:bg-olenium-bg py-32"
      id={props.id}
    >
      <div
        className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
        aria-hidden="true"
      >
        <div
          className="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] dark:from-slate-500 dark:to-slate-800"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div
        className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
        aria-hidden="true"
      >
        <div
          className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] dark:from-slate-500 dark:to-slate-800 xl:ml-0 xl:mr-[calc(50%-12rem)]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-7xl p-4 md:p-8 md:text-left xl:max-w-7xl justify-start">
          <p className="text-base font-semibold tracking-wide text-white uppercase pb-4">
            Testimonials
          </p>
          <h2 className="text-3xl tracking-tight text-grey-900 sm:text-4xl md:text-5xl lg:text-6xl font-extrabold text-gray-900 dark:text-white">
            What our clients
            <br />
            say about us
          </h2>
          <p className="mt-6 text-lg tracking-tight text-gray-600 dark:text-white xl:max-w-3xl">
            Don't just take our word for it—see what our satisfied customers
            have to say! Our testimonials showcase the real-world impact and
            benefits our users experience, highlighting our commitment to
            excellence and the value we bring to every project.{" "}
          </p>
        </div>

        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
          <figure className="rounded-2xl bg-white dark:bg-[#1D1D1D] shadow-lg ring-1 ring-gray-900/5 sm:col-span-2 xl:col-start-2 xl:row-end-1">
            <blockquote className="p-6 text-lg font-semibold leading-7 tracking-tight text-gray-900 dark:text-white sm:p-12 sm:text-xl sm:leading-8">
              <p>{`“${featuredTestimonial.body}”`}</p>
            </blockquote>
            <figcaption className="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-900/10 px-6 py-4 sm:flex-nowrap">
              <img
                className="h-10 w-10 flex-none rounded-full bg-gray-50"
                src={featuredTestimonial.author.imageUrl}
                alt=""
              />
              <div className="flex-auto">
                <div className="font-semibold dark:text-white">
                  {featuredTestimonial.author.name}
                </div>
                <div className="text-gray-600 dark:text-white">
                  {featuredTestimonial.author.company}
                </div>
              </div>
              <img
                className="h-10 w-auto flex-none"
                src={featuredTestimonial.author.logoUrl}
                alt=""
              />
            </figcaption>
          </figure>
          {testimonials.map((columnGroup, columnGroupIdx) => (
            <div
              key={columnGroupIdx}
              className="space-y-8 xl:contents xl:space-y-0"
            >
              {columnGroup.map((column, columnIdx) => (
                <div
                  key={columnIdx}
                  className={classNames(
                    (columnGroupIdx === 0 && columnIdx === 0) ||
                      (columnGroupIdx === testimonials.length - 1 &&
                        columnIdx === columnGroup.length - 1)
                      ? "xl:row-span-2"
                      : "xl:row-start-1",
                    "space-y-8"
                  )}
                >
                  {column.map((testimonial) => (
                    <figure
                      key={testimonial.author.handle}
                      className="rounded-2xl bg-white dark:bg-[#1D1D1D] p-6 shadow-lg ring-1 ring-gray-900/5"
                    >
                      <blockquote className="text-gray-900 dark:text-white">
                        <p>{`“${testimonial.body}”`}</p>
                      </blockquote>
                      <figcaption className="mt-6 flex items-center gap-x-4">
                        <img
                          className="h-10 w-10 rounded-full bg-gray-50"
                          src={testimonial.author.imageUrl}
                          alt=""
                        />
                        <div>
                          <div className="font-semibold dark:text-white">
                            {testimonial.author.name}
                          </div>
                          <div className="text-gray-600 dark:text-white">
                            {testimonial.author.company}
                          </div>
                        </div>
                      </figcaption>
                    </figure>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
