import ReactPlayer from "react-player";
import blog1 from "../assets/blogs/enviro.png";
import blog2 from "../assets/blogs/eventblog.jpg";
import blog3 from "../assets/blogs/warroom.png";

const posts = [
  {
    id: 1,
    title: "Enviro-Rod's New Website",
    href: "https://enviro-rod.co.uk/",
    imageUrl: blog1,
    date: "Industrial Services",
    datetime: "2020-03-16",
  },
  {
    id: 2,
    title: "War Room Laser Tag",
    href: "https://warroomlasertag.co.uk/",
    imageUrl: blog3,
    date: "Hospitality",
    datetime: "2020-03-10",
  },
  {
    id: 3,
    title: "Event Bench Hire",
    href: "https://eventbenchhire.co.uk/",
    imageUrl: blog2,
    date: "Equipment Hire",
    datetime: "2020-02-12",
  },
];

const videoStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
};

export default function Portfolio(props) {
  return (
    <div className="mx-auto md:text-left xl:max-w-7xl justify-center">
      <div className="mx-auto max-w-7xl px-6 lg:px-6">
        <div
          className="lg:col-span-3 my-10 rounded-2xl overflow-hidden shadow-xl"
          style={{
            // Hide on screens smaller than 768px
            '@media (max-width: 768px)': {
              display: 'none',
            },
          }}
        >
          <div style={{ transform: 'scale(1.1)' }}>
            <video
              autoPlay
              loop
              muted
              playsInline
              style={videoStyle}
              src="https://olenium-public-resources.s3.eu-west-1.amazonaws.com/boxVideo.mp4"
            />
          </div>
        </div>
        <p className="text-base font-semibold tracking-wide text-white uppercase py-4">
          Our most recent work
        </p>
        <h2 className="text-3xl tracking-tight text-balance pb-10 text-grey-900 sm:text-4xl md:text-5xl lg:text-6xl font-extrabold text-gray-900 dark:text-white">
          We build websites for <br />
          <a className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600">
            {" "}
            amazing
          </a>{" "}
          people
        </h2>
        <div className="bg-white dark:bg-olenium-bg" id={props.id}>
          <div className="mx-auto grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
              <article
                key={post.id}
                className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
              >
                <img
                  src={post.imageUrl}
                  alt=""
                  className="absolute inset-0 -z-10 h-full w-full object-cover"
                />
                <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                  <time dateTime={post.datetime} className="mr-8">
                    {post.date}
                  </time>
                </div>
                <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                  <a href={post.href}>
                    <span className="absolute inset-0" />
                    {post.title}
                  </a>
                </h3>
              </article>
            ))}
          </div>
        </div>
      </div>
    </div >
  );
}
