import { SiTailwindcss } from "react-icons/si";
import { FaAws } from "react-icons/fa";
import { IoShieldCheckmarkSharp } from "react-icons/io5";
import image from "../assets/cms2.png";
import { BiChip } from "react-icons/bi";
import { BsBarChartFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa6";

const features = [
  {
    name: "SideKick AI",
    description:
      "We use the latest in machine learning to give you insights and recommendations on how to improve your website.",
    icon: BiChip,
  },
  {
    name: "Inteligent Analytics",
    description:
      "Our advnatced analytics engine will give you insights into your website's performance and how to improve it.",
    icon: BsBarChartFill,
  },
  {
    name: "Freelancer Network",
    description:
      "Get intelignently matched through our network of freelancers to get design and development work done.",
    icon: FaUsers,
  },
];

export default function Example() {
  return (
    <div className="bg-white dark:bg-olenium-bg py-24">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="relative isolate overflow-hidden bg-gray-900 dark:text-white px-6 py-20 sm:rounded-3xl sm:px-10 sm:py-24 lg:py-24 xl:px-24">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center lg:gap-y-0">
            <div className="lg:row-start-2 lg:max-w-md">
              <h2 className="text-4xl font-bold tracking-tight text-white dark:text-white sm:text-6xl">
                Elaborately simple.
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-300 dark:text-white">
                Manage your next website project through our easy-to-use platform where you can chat with our team, get insights and recommendations, and even get matched with freelancers that specialise in your industry.
              </p>
            </div>
            <img
              src={image}
              alt="Product screenshot"
              className="relative -z-20 min-w-full max-w-xl rounded-xl shadow-xl ring-1 ring-white/10 lg:row-span-4 lg:w-[64rem] lg:max-w-none"
              width={2432}
              height={1442}
            />
            <div className="max-w-xl lg:row-start-3 lg:mt-10 lg:max-w-md lg:border-t lg:border-white/10 lg:pt-10">
              <dl className="max-w-xl space-y-8 text-base leading-7 text-white lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative">
                    <dt className="ml-9 inline-block font-semibold text-white">
                      <feature.icon
                        className="absolute left-1 top-1 h-5 w-5 text-olenium-blue"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline text-white">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div
            className="pointer-events-none absolute left-12 top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-3xl lg:bottom-[-12rem] lg:top-auto lg:translate-y-0 lg:transform-gpu"
            aria-hidden="true"
          >
            <div
              className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#00B4FF] to-[#00B4FF] opacity-25"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
