import { CheckIcon } from "@heroicons/react/solid";
import { RadioGroup } from "@headlessui/react";
import { Popover } from "@headlessui/react";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import { useState, useEffect } from "react";
import { useDarkMode } from "../utils/DarkModeContext";
import LightMode from "../assets/LightMode";
import DarkMode from "../assets/DarkMode";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const tiers = [
  {
    name: "One Page Package",
    id: "one-page",
    href: "#",
    price: "£995",
    sale: "£695",
    description:
      "Kickstart your digital presence with our One Page Package, the perfect blend of simplicity and professionalism. ",
    features: [
      "Bespoke design to match your brand, ensuring your one-page site stands out with a unique look and feel.",
      "Mobile-responsive layout for a seamless experience across all devices, from desktops to smartphones.",
      "1-hour dedicated support response to quickly address any issues and provide ongoing assistance.",
      "SEO essentials for better visibility, helping you rank higher in search results and attract more visitors.",
      "Professional copywriting services to craft compelling content that resonates with your audience, telling your story effectively.",
      "Direct design consultation for a personalised touch, aligning every element with your vision.",
      "Access to Olenium to provide you with dynamic business insights and advice to grow.",
      "Access to our global Freelancer network for any additional services you might need.",
    ],
  },
  {
    name: "Multi Page Package",
    id: "multi-page",
    href: "#",
    price: "£3,995",
    sale: "£2,795",
    description:
      "Elevate your online presence with our Multi Page Package, a comprehensive solution for businesses seeking to dominate their digital landscape.",
    features: [
      "Bespoke multi-page site design that captures the full scope of your business, providing ample space for all your content.",
      "Advanced analytics and SEO strategies to track performance, improve search rankings, and drive more targeted traffic.",
      "1-hour dedicated support response for premium assistance, ensuring your website remains in peak condition.",
      "Marketing and automation tools to streamline your digital campaigns, enhancing efficiency and conversion rates.",
      "E-commerce integration for online sales, turning your site into a powerful retail platform with secure transaction capabilities.",
      "Unlimited modules to add functionality as your business grows, from booking systems to dynamic lead generation forms",
      "Access to Olenium to provide you with dynamic business insights and advice to grow.",
      "Access to our Freelancer network, offering specialised skills for any project needs, ensuring your website remains innovative and current.",
      "Social media integration for enhanced engagement, seamlessly connecting your site with major platforms to expand your reach.",
      "Professional copywriting services to craft compelling content that resonates with your audience, telling your story effectively.",
      "Multilingual support to cater to a global audience, breaking language barriers and opening up new markets for your business.",
    ],
  },
];

export default function Example() {
  const [scrolling, setScrolling] = useState(false);
  const { isDarkMode, toggleDarkMode } = useDarkMode();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > 10) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const saveTier = (tier) => {
    localStorage.setItem("tier", tier);
  };

  return (
    <div className="isolate overflow-hidden bg-white dark:bg-olenium-bg">
      <Popover
        className={`sticky top-0 z-50 bg-white ${scrolling
          ? "bg-opacity-40 dark:bg-opacity-40"
          : "bg-transparent dark:bg-transparent"
          } dark:bg-olenium-bg transition-colors duration-500 ease-in-out backdrop-blur-md`}
      >
        <div className="relative z-20">
          <div className="max-w-full md:max-w-full lg:max-w-7xl xl:max-w-7xl mx-4 md:mx-8 lg:mx-auto xl:mx-auto flex justify-between items-center px-4 py-3 sm:px-6 sm:py-3 lg:px-12 xl:px-16">
            <button
              onClick={toggleDarkMode}
              className="p-2 rounded text-2xl order-1"
            >
              {isDarkMode ? <LightMode /> : <DarkMode />}
            </button>
            <div className="order-2">
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <Link
              to="/"
              className="rounded-md bg-gray-700 dark:bg-white/10 px-3.5 py-1.5 text-sm font-semibold text-white hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-olenium-blue order-3"
            >
              Learn more
            </Link>
          </div>
        </div>
      </Popover>
      <div className="mx-auto max-w-7xl px-6 pb-96 pt-24 text-center sm:pt-32 lg:px-8">
        <div className="mx-auto max-w-4xl">
          <p className="mt-2 text-4xl font-bold tracking-tight text-black dark:text-white">
            Choose the perfect package to help to get your business up and running.
          </p>
        </div>
        <div className="relative mt-6">
          <p className="mx-auto max-w-2xl text-lg leading-8 text-gray-800 dark:text-white">
            Choose the package that best fits your needs. If you need help,
            we're here to help you choose the right package for your business.
          </p>
        </div>
        <br />
      </div>
      <div className="flow-root bg-white pb-36 dark:bg-olenium-bg">
        <div className="-mt-80">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2">
              {tiers.map((tier) => (
                <div
                  key={tier.id}
                  className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10"
                >
                  <div>
                    <h3
                      id={tier.id}
                      className="text-base font-semibold leading-7 text-[#0085ff]"
                    >
                      {tier.name}
                    </h3>
                    <div className="mt-4 flex items-baseline gap-x-2">
                      <span className="text-5xl font-bold tracking-tight text-gray-900 ">
                        <div className="decoration-black text-decoration-thickness-1 text-3xl text-gray-800">
                          {tier.price}
                        </div>
                      </span>
                    </div>
                    <span className="text-base font-semibold leading-7 text-gray-600">
                      One time payment
                    </span>
                    <Link
                      to="/choose-hosting"
                      onClick={() => saveTier(tier.id)}
                      aria-describedby={tier.id}
                      className="mt-8 block rounded-md bg-[#0085ff] px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-olenium-blue"
                    >
                      Choose Package
                    </Link>
                    <p className="mt-6 text-base leading-7 text-gray-600">
                      {tier.description}
                    </p>
                    <ul
                      role="list"
                      className="mt-10 space-y-4 text-sm leading-6 text-gray-600"
                    >
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex gap-x-3">
                          <CheckIcon
                            className="h-6 w-5 flex-none text-olenium-blue"
                            aria-hidden="true"
                          />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
              <div className="flex flex-col items-start gap-x-8 gap-y-6 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center dark:ring-white">
                <div className="lg:min-w-0 lg:flex-1">
                  <h3 className="text-lg font-semibold leading-8 tracking-tight text-olenium-blue dark:text-white">
                    Need help choosing the right package?
                  </h3>
                  <p className="mt-1 text-base leading-7 text-gray-600 dark:text-white">
                    Feel free to book a call with us and we'll help you choose
                    the right package for your business.
                  </p>
                </div>
                <a
                  href="https://calendar.app.google/SvtsyuUYwntV6Mqp7"
                  className="rounded-md px-3.5 py-2 text-sm font-semibold leading-6 text-olenium-blue ring-1 ring-inset ring-olenium-blue hover:ring-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-oledium-blue dark:text-white dark:ring-white dark:hover:ring-white dark:focus-visible:ring-white"
                >
                  Book a call <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-black" aria-labelledby="footer-heading">
        <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:py-8 lg:px-8">
          <div className="pt-8 lg:flex lg:items-center lg:justify-between xl:mt-0"></div>
          <div className="mt-8 border-t border-white pt-8 md:flex md:items-center md:justify-between lg:mt-0">
            {/* <p className="mt-8 text-base text-white md:mt-0 md:order-1">
            &copy; {new Date().getFullYear()} It's One Page
          </p> */}
            <svg
              width="100"
              height="20"
              viewBox="0 0 694 214"
              className="fill-white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clipRule="evenodd"
                d="M0 106C0 47.4588 47.4588 0 106 0C164.512 0 211.971 47.4588 212 106C212 164.541 164.541 212 106 212C47.4588 212 0 164.541 0 106ZM105.5 174C142.779 174 173 143.779 173 106.5C173 69.2208 142.779 39 105.5 39C68.2208 39 38 69.2208 38 106.5C38 143.779 68.2208 174 105.5 174Z"
              />
              <path d="M243 110.267V34.3774C319.429 53.479 395.887 72.5805 472.317 91.682C472.317 71.7475 472.317 51.7842 472.317 31.8497C472.317 14.8163 486.067 1.02872 503.054 1H512.107V213.099H503.914C486.267 213.099 472.001 198.708 472.116 181.043C472.202 168.088 472.259 155.134 472.345 142.179L290.811 95.5024C290.811 111.961 290.754 128.449 290.754 144.908L277.261 144.822C258.297 144.707 243 129.282 243 110.267Z" />
              <path d="M693.183 82.8546H550V131.326H693.183V82.8546Z" />
              <path d="M550 162.737H693.183V179.233C693.183 196.884 678.87 211.208 661.233 211.208H550V162.737Z" />
              <path d="M551.578 3H661.289C678.898 3 693.211 17.3242 693.211 34.9475V49.892C693.211 50.7661 692.506 51.4711 691.633 51.4711H551.578C550.704 51.4711 550 50.7661 550 49.892V4.57905C550 3.70493 550.704 3 551.578 3Z" />
            </svg>
            <p className="mt-8 text-base text-white md:mt-0 md:order-1">
              Developed by{" "}
              <a
                href="https://olenium.co.uk"
                className="text-white hover:text-gray-300"
              >
                Olenium
              </a>{" "}
              in Newcastle
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
