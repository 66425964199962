import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./components/Header";
import Hero from "./components/Hero";
import Services from "./components/Services";
import Pricing from "./components/Pricing";
import Portfolio from "./components/Portfolio";
import CallToAction from "./components/Testimonials";
import Footer from "./components/Footer";
import Features from "./components/Features";
import GetStarted from "./components/GetStartedPackage";
import GetStartedHosting from "./components/GetStartedHosting";
import { DarkModeProvider } from "./utils/DarkModeContext";
import FeatureGrid from "./components/FeaturesGrid";
import HostingGrid from "./components/HostingGrid";
import useIntercom from "./components/useIntercom";

export default function App() {

  const user = {
    name: "",
    id: "",
    email: "",
    createdAt: Math.floor(Date.now() / 1000) // Example user data
  };

  useIntercom(user); // Initialize Intercom with user data


  return (
    <Router>
      <div className="bg-white dark:bg-olenium-bg overflow-clip">
        <DarkModeProvider>
          <Routes>
            <Route
              path="/"
              element={
                <React.StrictMode>

                  <Header />
                  <Hero />
                  <Portfolio id="portfolio" />
                  <FeatureGrid />
                  <Features id="features" />
                  <HostingGrid />
                  <Services />
                  <Pricing id="pricing" />
                  <CallToAction id="testimonials" />
                  <Footer />
                </React.StrictMode>
              }
            />
            <Route path="/get-started" element={<GetStarted />} />
            {/* allow for url params */}
            <Route path="/choose-hosting" element={<GetStartedHosting />} />
          </Routes>
        </DarkModeProvider>
      </div>
    </Router>
  );
}
