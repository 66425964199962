import React from "react";

function LightMode() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 121 121"
      className="fill-black dark:fill-white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="60.5" cy="60.5" r="60.5" fill="#F1F1F1" fill-opacity="0.1" />
      <path d="M75.8837 61C75.8837 69.2201 69.2201 75.8837 61 75.8837C52.7799 75.8837 46.1163 69.2201 46.1163 61C46.1163 52.7799 52.7799 46.1163 61 46.1163C69.2201 46.1163 75.8837 52.7799 75.8837 61Z" />
      <path d="M61 42.3953C62.2263 42.3793 63.2165 41.3891 63.2326 40.1628V31.2326C63.2326 29.9996 62.233 29 61 29C59.767 29 58.7674 29.9996 58.7674 31.2326V40.1628C58.7835 41.3891 59.7737 42.3793 61 42.3953Z" />
      <path d="M61 79.6047C59.7737 79.6207 58.7835 80.6109 58.7674 81.8372V90.7674C58.7674 92.0004 59.767 93 61 93C62.233 93 63.2326 92.0004 63.2326 90.7674V81.8372C63.2165 80.6109 62.2263 79.6207 61 79.6047Z" />
      <path d="M44.6874 47.8428C45.5591 48.7134 46.9711 48.7134 47.8428 47.8428C48.7133 46.9711 48.7133 45.5591 47.8428 44.6874L41.5321 38.3767C40.9755 37.7795 40.1373 37.5336 39.3463 37.7356C38.5553 37.9376 37.9376 38.5553 37.7356 39.3463C37.5336 40.1373 37.7795 40.9755 38.3767 41.5321L44.6874 47.8428Z" />
      <path d="M77.3126 74.1572C76.4329 73.3375 75.0621 73.3617 74.2119 74.2119C73.3617 75.0621 73.3375 76.4329 74.1572 77.3126L80.4679 83.6233C80.8843 84.0449 81.453 84.2809 82.0456 84.2781C82.6388 84.2844 83.2088 84.0478 83.6233 83.6233C84.4938 82.7516 84.4938 81.3396 83.6233 80.4679L77.3126 74.1572Z" />
      <path d="M42.3953 61C42.3793 59.7737 41.3891 58.7835 40.1628 58.7674H31.2326C29.9996 58.7674 29 59.767 29 61C29 62.233 29.9996 63.2326 31.2326 63.2326H40.1628C41.3891 63.2165 42.3793 62.2263 42.3953 61Z" />
      <path d="M90.7674 58.7674H81.8372C80.6042 58.7674 79.6047 59.767 79.6047 61C79.6047 62.233 80.6042 63.2326 81.8372 63.2326H90.7674C92.0004 63.2326 93 62.233 93 61C93 59.767 92.0004 58.7674 90.7674 58.7674Z" />
      <path d="M44.6874 74.1572L38.3767 80.4679C37.5062 81.3396 37.5062 82.7516 38.3767 83.6233C38.7912 84.0478 39.3612 84.2844 39.9544 84.2781C40.547 84.2809 41.1157 84.0449 41.5321 83.6233L47.8428 77.3126C48.6625 76.4329 48.6383 75.0621 47.7881 74.2119C46.9379 73.3617 45.5671 73.3375 44.6874 74.1572Z" />
      <path d="M75.8837 48.4977C76.4763 48.5005 77.045 48.2644 77.4614 47.8428L83.6233 41.5321C84.2206 40.9755 84.4664 40.1373 84.2644 39.3463C84.0624 38.5553 83.4447 37.9376 82.6537 37.7356C81.8627 37.5336 81.0245 37.7795 80.4679 38.3767L74.1572 44.6874C73.2867 45.5591 73.2867 46.9711 74.1572 47.8428C74.609 48.3034 75.2401 48.5428 75.8837 48.4977Z" />
    </svg>
  );
}

export default LightMode;
